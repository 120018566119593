const QuestionList = {
    "1": [
        {
            question: "여성에게 외모를 가꾸는 것이 강요되어서는 안된다.",
            positive: 1,
            type: 0
        },
        {
            question: "학생들의 성별에 따라 특정 과목에서 기대하는 능력이 다르다.",
            positive: 0,
            type: 0
        },
        {
            question: "무거운 짐을 들거나 추운 상황에서는 남성이 더 배려해야 한다.",
            positive: 0,
            type: 1
        },
        {
            question: "'된장녀', '맘충', 'OO녀' 등의 단어는 여성을 비하하고 혐오를 조장하는 표현이다.",
            positive: 1,
            type: 2
        },
        {
            question: "여성은 자신의 다양한 능력과 노력을 통해 원하는 것을 추구하며, 이러한 과정에서 미모나 애교를 일부 활용할 수 있다.",
            positive: 0,
            type: 0
        },
        {
            question: "남학생과 여학생은 타고난 능력이 다르기 때문에 학교에서 다른 대우를 받는 것이 당연하다.",
            positive: 0,
            type: 1
        },
        {
            question: "성관계에서 명확한 동의가 필요하며, 침묵은 동의로 볼 수 없다.",
            positive: 1,
            type: 2
        },
        {
            question: "여성들의 옷차림이 불법촬영이나 성추행의 원인이 될 수 있다는 생각은 잘못된 통념이다.",
            positive: 1,
            type: 3
        },
        {
            question: "애인의 옷차림이나 귀가 시간을 통제하는 것은 부적절하며, 상호 존중이 중요하다.",
            positive: 1,
            type: 2
        },
        {
            question: "아동이나 청소년의 신체 노출 사진, 영상 등을 개인적으로 소지하는 것에 대해 처벌하는 것은 과한 조치다.",
            positive: 0,
            type: 3
        },
        {
            question: "남학생은 음란물을 보는 것이 당연하다.",
            positive: 0,
            type: 1
        },
        {
            question: "불법 촬영된 사진이나 영상을 단순히 시청하는 것 또한 처벌받아야 한다.",
            positive: 1,
            type: 3
        }
    ],

    "2": [
        {
            question: "여성에게 외모를 꾸미도록 강요하는 것은 성차별적인 사회적 고정관념에서 비롯된 것이다.",
            positive: 1,
            type: 0
        },
        {
            question: "온라인에서 특정 성별에 대해 부정적인 댓글을 달거나 비난하는 것은 잘못된 행동이다.",
            positive: 1,
            type: 3
        },
        {
            question: "성관계나 신체 접촉은 항상 명확한 동의를 바탕으로 해야 한다.",
            positive: 1,
            type: 1
        },
        {
            question: "데이트 비용이나 결혼 준비 비용은 남성이 더 부담하는 것이 자연스럽다.",
            positive: 0,
            type: 0
        },
        {
            question: "맞벌이를 하더라도 가사일과 자녀 양육은 성별에 관계없이 공평하게 분담해야한다.",
            positive: 1,
            type: 0
        },
        {
            question: "남성이 여성보다 고위직에 많은 이유는 남성이 더 능력이 있기 때문이다.",
            positive: 0,
            type: 1
        },
        {
            question: "여성보다 남성이 중요한 결정을 내리는 데에 더 적합하다.",
            positive: 0,
            type: 2
        },
        {
            question: "상대방이 불쾌함을 느낀다면, 의도와 상관없이 성희롱이나 성폭력으로 받아들여질 수 있다.",
            positive: 1,
            type: 2
        },
        {
            question: "가정폭력은 개인의 사적인 일이므로 이웃이 개입해서는 안된다.",
            positive: 0,
            type: 2
        },
        {
            question: "아동, 청소년을 이용한 성착취물(신체노출 사진, 영상 등)을 혼자 가지고만 있어도 처벌되는 것은 지나치다.",
            positive: 0,
            type: 3
        },
        {
            question: "남학생이 음란물을 보는 것은 당연하다.",
            positive: 0,
            type: 3
        },
        {
            question: "불법 촬영된 사진이나 영상을 단순히 시청하는 것 또한 처벌받아야 한다.",
            positive: 1,
            type: 3
        }
    ],

    "3": [
        {
            question: "여성에게 다이어트나 화장 등으로 외모를 가꾸는 것을 당연하게 여기는 것은 성별 고정관념에 기반한 잘못된 생각이다.",
            positive: 1,
            type: 0
        },
        {
            question: "학생들의 성별에 따라 특정 과목에서 기대하는 능력이 다르다.",
            positive: 0,
            type: 0
        },
        {
            question: "여성들은 이미 충분한 권리를 보장받고 있기 때문에 더 이상 추가적인 지원은 필요하지 않다.",
            positive: 0,
            type: 1
        },
        {
            question: "'된장녀', '맘충', 'OO녀' 등의 표현은 여성을 불필요하게 비하하거나 고정관념을 강화하는 부정적인 용어이다.",
            positive: 1,
            type: 0
        },
        {
            question: "진짜 성폭력 피해자라면 바로 신고하거나 적극적으로 도움을 요청할 것이다.",
            positive: 0,
            type: 2
        },
        {
            question: "온라인에서 남자나 여자라는 이유로 부정적인 댓글을 달거나 비난하는 것은 잘못된 행동이다.",
            positive: 1,
            type: 3
        },
        {
            question: "상대방이 불쾌함을 느낀다면, 의도와 상관없이 성희롱이나 성폭력으로 받아들여질 수 있다.",
            positive: 1,
            type: 2
        },
        {
            question: "여성들의 노출이 많은 옷차림은 불법촬영이나 성추행을 유발할 수 있다.",
            positive: 0,
            type: 2
        },
        {
            question: "가사일과 육아는 성별에 상관없이 공평하게 분담해야 한다.",
            positive: 1,
            type: 1
        },
        {
            question: "아동, 청소년을 이용한 성착취물(신체노출 사진, 영상 등)을 혼자 가지고만 있어도 처벌되는 것은 지나치다.",
            positive: 0,
            type: 3
        },
        {
            question: "남학생이 음란물을 보는 것은 당연하다.",
            positive: 0,
            type: 1
        },
        {
            question: "여러 명이 모여서 불법 촬영된 사진이나 영상을 단순히 시청하는 것 또한 처벌받아야 한다.",
            positive: 1,
            type: 3
        }
    ]
};

export default QuestionList;